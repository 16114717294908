<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Turno</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Internos</li>
                  <li class="breadcrumb-item active">Operaciones</li>
                  <li class="breadcrumb-item active">Solicitud</li>
                  <li class="breadcrumb-item active">Formulario</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2 border-0">
                <div class="col-6 pt-2 pb-2">
                  <h5 class="mb-0">Formulario {{ accion }} Solicitud</h5>
                </div>
              </div>
              <div class="card-body p-0">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="tab-Principal"
                      data-toggle="tab"
                      href="#Principal"
                      >Principal</a
                    >
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade active show" id="Principal">
                    <div class="card-body">
                      <div class="row">
                        <div class="form-group col-md-4">
                          <label for="odometro">Odometro</label>
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            id="odemetro"
                            v-model="form.odometro_fin"
                            :class="
                              $v.form.odometro_fin.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            @blur="validarOdometro()"
                          />
                        </div>
                        <div
                          class="form-group col-md-3"
                          v-if="
                            form.foto_odometro_fin == null ||
                              form.foto_odometro_fin == ''
                          "
                        >
                          <label for="imagen">Foto Odometro Final</label>
                          <input
                            type="file"
                            class="form-control-file"
                            accept="image/x-png, image/gif, image/jpeg"
                            @change="obtenerImagen"
                          />
                        </div>
                        <div class="form-group col-md-4">
                          <figure class="form-group" v-if="miniLogo">
                            <div class="button-container">
                              <img
                                :src="miniLogo"
                                alt="Logo"
                                width="236"
                                height="125"
                                class="rounded mx-auto d-block"
                              />
                              <a
                                @click="destroyImage()"
                                id="BotonEliminar"
                                v-if="
                                  form.foto_odometro_fin &&
                                    $store.getters.can(
                                      'tep.viajes.deleteOdometroFinal'
                                    )
                                "
                                href="#"
                              >
                                Eliminar
                                <i class="fas fa-window-close"></i>
                              </a>
                            </div>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button
                    class="btn bg-primary col-md-1"
                    v-if="
                      archivoValido &&
                        form.odometro_fin &&
                        $store.getters.can('tep.viajes.odometroFinal')
                    "
                    @click="saveImage()"
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>

                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
export default {
  name: "TepViajeOdoFinal",
  components: {
    Loading,
  },
  data() {
    return {
      cargando: false,
      miniLogo: null,
      archivoValido: false,
      fileFotografia: null,
      accion: "",
      metodo: "",
      error: "",
      form: {
        viaje: {},
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  validations() {
    return {
      form: {
        odometro_fin: {
          required,
        },
      },
    };
  },
  methods: {
    obtenerImagen(e) {
      this.miniLogo = null;
      this.archivoValido = false;
      if (e.target.files[0]) {
        this.cargando = true;
        const file = e.target.files[0];
        if (this.validaArchivo(file)) {
          this.archivoValido = true;
          this.fileFotografia = file;
          this.cargarImagen(file);
        }
      }
    },

    validaArchivo(file) {
      let flag = true;
      if (file.size > 1024000) {
        flag = false;
        this.fileFotografia = null;
        this.cargando = false;
        this.$swal({
          icon: "error",
          title: `El Archivo es muy pesado para subirlo`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return flag;
    },

    async validarOdometro() {
      if (this.form.odometro_fin < this.form.vehiculo.odometro) {
        await this.$swal({
          text:
            "El valor del Odometro no puede ser menor al Odometro Actual del vehiculo. Odometro Vehiculo " +
            this.form.vehiculo.odometro,
          icon: "warning",
          confirmButtonText: "Aceptar!",
        });
        this.form.odometro_fin = null;
      }
      let diferenciaOdo = this.form.odometro_fin - this.form.odometro_ini;
      /*if (diferenciaOdo > 500) {
        await this.$swal({
          text:
            "El valor de los odometros no puede superar los 500 KM valor dado: " +
            diferenciaOdo,
          icon: "warning",
          confirmButtonText: "Aceptar!",
        });
        this.form.odometro_fin = null;
      }*/
      if (this.form.odometro_fin < this.form.odometro_ini) {
        await this.$swal({
          text:
            "El valor del odómetro final no puede ser menor al odómetro incial " +
            this.form.odometro_ini,
          icon: "warning",
          confirmButtonText: "Aceptar!",
        });
        this.form.odometro_fin = null;
      }
    },

    cargarImagen(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.miniLogo = e.target.result;
      };
      reader.readAsDataURL(file);
      this.cargando = false;
    },

    saveImage() {
      this.cargando = true;
      let formData = new FormData();
      formData.append("id", this.form.id);
      formData.append("odometro_fin", this.form.odometro_fin);
      formData.append("link_fotografia", this.fileFotografia);
      axios
        .post("api/tep/viajes/odometroFin", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.cargando = false;
          this.$swal({
            icon: "success",
            title: "Los datos se guardaron exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.archivoValido = false;
          this.form.foto_odometro_fin = response.data.foto_odometro_fin;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    destroyImage() {
      this.$swal({
        title: "Esta seguro de eliminar esta Imagen?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete("api/tep/viajes/odometroFin/" + this.form.id)
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "La imagen se elimino correctamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch(function(error) {
              this.$swal({
                icon: "error",
                title: "Ocurrio un error" + error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
          this.miniLogo = null;
          this.form.foto_odometro_fin = null;
        }
      });
    },
    back() {
      return this.$router.replace("/Tep/Viajes");
    },

    init() {
      this.accion = this.$route.params.accion;
      if (this.$route.params.accion == "Final") {
        this.form = this.$route.params.data_viaje;
        this.miniLogo = this.uri_docs + this.form.foto_odometro_fin;
        this.metodo = "PUT";
      }
    },
  },

  async mounted() {
    this.cargando = true;
    await this.init();
    this.cargando = false;
  },
};
</script>
<style>
.button-container {
  display: inline-block;
  position: relative;
}

.button-container a {
  bottom: 3em;
  right: 4em;
  color: white;
  text-transform: uppercase;
  padding: 1em 1.8em;
  opacity: 0.3;
  color: #fff !important;
  background: #ff0000;
  position: absolute;
  outline: 2px solid;
  outline-color: rgba(248, 8, 8, 0.89);
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}

.button-container a:hover {
  box-shadow: inset 0 0 20px rgba(248, 8, 8, 0.89),
    0 0 20px rgba(248, 8, 8, 0.89);
  outline-color: rgba(49, 138, 172, 0);
  outline-offset: 80px;
  text-shadow: 3px 3px 3px #fff;
}
</style>
